import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import UIImageViewer from '../base/UIImageViewer';
import { getDownloadGameInfo } from '../../lib/device-detector';
import Image from 'next/image';
import Light from '../../public/images/home/teaser_3_light.webp';

const SliderStyled = styled(Slider)`
    .slick-dots {
        display: flex;
        justify-content: center;
        bottom: ${props => props.theme.spacing(3)};

        li {
            button:before {
                color: ${props => props.theme.vars.palette.neutral['800']};
                font-size: 12px;
            }
            
            &.slick-active button:before {
                color: ${props => props.theme.vars.palette.neutral['50']};
            }
        }
    }
`;

const Container = styled(Stack)`
    padding: ${props => props.theme.spacing(7.5, 5)};

    ${props => props.theme.breakpoints.up("sm")} {
        padding: ${props => props.theme.spacing(7.5, 0)};
    }
`;

const GridItem = styled(Grid)`
    padding: ${props => props.theme.spacing(5)};
    text-align: center;

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(5, 10)};
    }
`;

const ContentBox = styled(Stack)`
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: ${props => props.theme.spacing(0, 'auto')};

    ${props => props.theme.breakpoints.up("lg")} {
        text-align: left;
        align-items: start;
    }

    .brand {
        background: linear-gradient(284.43deg, #FF00C7 -120.75%, #8F00FF 125.12%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
    }
`;

const RobotsImage = styled(UIImageViewer)`
    height: auto;
    width: 100%;
    border-radius: ${props => props.theme.spacing(3)};

    &.xl {
        height: auto;
        width: 100%; 
    }   
`;

const ButtonStyled = styled(Button)`
    width: 350px;
    background-color: ${props => props.theme.vars.palette.green['400']};
    &:hover {
        background-color: ${props => props.theme.vars.palette.green['400']};
        opacity: 0.9;
    }
` as any;

const LightImage = styled(Image)`
    position: absolute;
    width: 130px;
    height: 90px;
    top: 50%;
    right: 50%;
    z-index: -1;
    background: #BC763C;
    filter: blur(125px);
`;

const Teaser3 = () => {
    const router = useRouter();
    const [downloadGameInfo, setDownloadGameInfo] = useState({ url: '', device: '' });
    const slider = useRef<any>(null);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [],
        autoplay: true,
        autoplaySpeed: 5000,
    };

    useEffect(() => {
        setDownloadGameInfo(getDownloadGameInfo());
    }, []);

    return (
        <SliderStyled className="slickSlider" ref={slider}{...settings}>
            <Container>
                <Grid container>
                    <GridItem xs={12} lg={6}>
                        <UIImageViewer src="/images/home/gems.webp" minHeight={300} alt="Gems" />
                    </GridItem>
                    <GridItem xs={12} lg={6}>
                        <ContentBox spacing={3}>
                            <Typography variant='h3' color="neutral.100" component="div">Unleash the power</Typography>
                            <Typography variant='body1'>
                                Every NANO comes with its own stats and a power gem that gives secret abilities to its owner. Today we know 8 different gem types. Some of them are more common others are incredibly rare. If you control and use the power wisely, you will smash your opponents and win.
                            </Typography>
                            <ButtonStyled href={downloadGameInfo.url} target={"_blank"} variant="contained">Download game</ButtonStyled>
                        </ContentBox>
                    </GridItem>
                    <LightImage src={Light} alt="Teaser 3 lighting" width={250} height={272}/>
                </Grid>
            </Container>

            {false &&
                <Container>
                    <Grid container>
                        <GridItem xs={12} lg={6}>
                            <UIImageViewer src="/images/home/nanos.webp" minHeight={300} alt="Showroom nanos" />
                        </GridItem>
                        <GridItem xs={12} lg={6}>
                            <ContentBox spacing={3}>
                                <Typography variant='h3' color="neutral.100" component="div">WHO CAN STOP YOU? (TO-DO)</Typography>
                                <Typography variant='body1'>
                                    Each base Nano has 4 variations from different realms. Each one of them is unique in character and will help you get the win!
                                </Typography>
                                <Button disabled sx={{ width: '250px' }} href={"/showroom"} target={"_blank"} variant="outlined">Showroom</Button>
                            </ContentBox>
                        </GridItem>
                    </Grid>
                </Container>
            }

        </SliderStyled>
    );
}

export default Teaser3;

