import { useState } from "react";
import { styled } from '@mui/material/styles';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Box, Button, Checkbox, FormControlLabel, Link, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const Container = styled(Box)`
    padding: ${props => props.theme.spacing(7.5, 5)};

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5)};
    }

    ${props => props.theme.breakpoints.up("xl")} {
        padding: ${props => props.theme.spacing(7.5, 20)};
    }

    /* ${props => props.theme.breakpoints.up("lg")} {
        border-right: 1px solid ${props => props.theme.vars.palette.neutral['800']};
    } */

    .newsletterCheckbox {
        align-items: flex-start;
    }
`;

const GridItem = styled(Grid)`
    text-align: center;

    ${props => props.theme.breakpoints.up("xl")} {
        text-align: left;
    }
`

const NewsletterSubscribeSection = () => {
    const url = process.env.NEXT_PUBLIC_MAILCHIMP_URL || '';
    const [email, setEmail] = useState<any>('');
    const [validationError, setValidationError] = useState<any>('');
    const [dppChecked, setDppChecked] = useState<any>(false);

    const handleChecked = (event: any, checked: any) => {
        setDppChecked(checked);
    }

    const handleSubmit = async (e: any, subscribe: any) => {
        e.preventDefault();
        setValidationError(null);
        if (!email || email.indexOf("@") < 0 || email.indexOf(".") < 0) {
            setValidationError('Please enter a valid email address' as any);
            return false;
        }

        if (!dppChecked) {
            setValidationError('You need to acknowledge the data protection declaration' as any);
            return false;
        }

        await subscribe({
            EMAIL: email,
        });

        setEmail('');
        setDppChecked(false);
        return true;
    }

    return (
        <Container>
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status }) => (
                    <Grid container spacing={3}>
                        <GridItem xs={12}>
                            <Typography variant='h4' color="neutral.100">SUBSCRIBE TO OUR NEWSLETTER AND STAY UPDATED!</Typography>
                        </GridItem>
                        <GridItem xs={12}>
                            {status === "error" && <Typography color="red.200" variant="body1">This email is already subscribed!</Typography>}
                            {status === "success" && <Typography color="green.400" variant="body1">Success! Check your mails and confirm your subscription.</Typography>}
                            {validationError && <Typography color="red.200" variant="body1">{validationError}</Typography>}
                        </GridItem>
                        <GridItem xs={12}>
                            <form onSubmit={(e) => handleSubmit(e, subscribe)}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid xs={8}>
                                        <TextField size="small" variant="outlined" value={email} type={"email"} onChange={(e) => setEmail(e.target.value)} placeholder={"Your email"} />
                                    </Grid>
                                    <Grid xs={4}>
                                        <Button size="small" sx={{ width: '100%' }} disabled={!dppChecked} type={"submit"} variant="contained">Subscribe</Button>
                                    </Grid>
                                </Grid>
                                <Grid xs={12}>
                                    <FormControlLabel className="newsletterCheckbox" control={<Checkbox
                                        checked={dppChecked}
                                        onChange={handleChecked} />}
                                        label={<Box style={{ display: 'flex', justifyContent: 'stretch'}}>
                                            <Typography variant="xs" style={{ textAlign: 'justify' }}>I wish to receive information and exclusive offerings via email and consent for this purpose to the processing of my personal data to the extent outlined in the
                                                <Link href="/data-protection-declaration#_Toc25" color="brand.100" target="_blank"> Data Protection Declaration </Link> under point 2.5 (Newsletter). I may withdraw my consent at any time with effect for the future (e.g., by clicking the respective unsubscribe link provided at the end of each newsletter).
                                            </Typography>
                                        </Box>}
                                    />
                                </Grid>
                            </form>
                        </GridItem>
                    </Grid>
                )}
            />
        </Container>
    )
}

export default NewsletterSubscribeSection;