import { styled } from '@mui/material/styles';
import {Button, Stack, Typography} from "@mui/material";

const Container = styled(Stack)`
    text-align: center;
    align-items: center;
    margin: 0 auto;
    padding: ${props => props.theme.spacing(0, 5)};

    ${props => props.theme.breakpoints.up("md")} {
        text-align: left;
        align-items: flex-start;
        margin: 0;
    }
`;

const Lore = () => {
    return (
        <Container spacing={3}>
            <Typography variant='h3' color="neutral.100">How it began</Typography>
            <Typography variant='body1'>
            Satoshi Nakanano - a computer science genius - created the most advanced AI program humanity has ever seen. The sole purpose of the AI was to be able to create a wonderful metaverse based on reality. Thus, it created the NANOverse, magical lands with little creatures called “NANOs”. One day, something went terribly wrong. The AI turned evil and made a huge update on the NANOverse. This was the beginning of the NANO Games which will determine the fate of NANOs and humanity…
            </Typography>
            <Button sx={{width: '220px'}}  href={"https://docs.metananos.com/game-metaverse/the-lore"} target={"_blank"} variant="contained">Read the lore</Button>
        </Container>
    );
}

export default Lore;