import { SvgIcon } from "@mui/material";

const ArtstationIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m2 16.573 1.682 2.91A2.03 2.03 0 0 0 5.478 20.6h11.21l-2.325-4.026H2ZM22 16.592c0-.397-.113-.775-.321-1.097L15.1 4.078A2.002 2.002 0 0 0 13.323 3H9.845l10.151 17.58 1.607-2.778c.303-.53.397-.757.397-1.21ZM12.718 13.7 8.2 5.853 3.664 13.7h9.054Z"/></svg>
        </SvgIcon>
    );
}

export default ArtstationIcon;
