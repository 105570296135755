import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import UIImageViewer from '../base/UIImageViewer';
import Lore from './Lore';
import Image from 'next/image';
import Light from '../../public/images/home/teaser_2_light.webp';

const Container = styled(Grid)`
    ${props => props.theme.breakpoints.down("md")} {
        padding: ${props => props.theme.spacing(5, 0)};
    }
`;

const GridItem = styled(Grid)`
    position: relative;
    padding: ${props => props.theme.spacing(5)};
    border: none;

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5, 10)};
    }

    &.border {
        border: none;

        ${props => props.theme.breakpoints.up("xl")} {
            padding: ${props => props.theme.spacing(7.5, 20)};
        }

        /* ${props => props.theme.breakpoints.up("md")} {
            border-right: 1px solid ${props => props.theme.vars.palette.neutral['800']};
        } */
    }    
`;

const LightImage = styled(Image)`
   position: absolute;
    width: 330px;
    height: 330px;
    right: 0;
    top: 20%;
    background: ${props => props.theme.vars.palette.brand['200']};
    filter: blur(125px);
    z-index: -1;
`;

const Teaser2 = () => {
    return (
        <Container container>
            <GridItem xs={12} md={6} className="border">
                <UIImageViewer src="/images/home/lore.webp" minHeight={448} alt="Lore" />
                <LightImage src={Light} alt="Teaser 2 lighting" width={250} height={272}/>
            </GridItem>
            <GridItem xs={12} md={6}>
                <Lore />
            </GridItem>
        </Container>
    );
}

export default Teaser2;

