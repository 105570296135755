import { styled } from '@mui/material/styles';
import { Stack } from "@mui/material";
  
const ButtonGroupStyled = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    & > *:not(:last-child) {
        margin-right:  ${props => props.theme.spacing(2)};
    }

    button, a {
        display: flex;
    }
`;

const UIButtonGroup = (props) => {
    return <ButtonGroupStyled {...props}>{props.children}</ButtonGroupStyled>;
};
  
  export default UIButtonGroup;