import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Head from 'next/head';
import Teaser from "../components/home/Teaser";
// import Headlines from "../components/home/Headlines";
import WhatIsMetananos from "../components/home/WhatIsMetananos";
import Teaser2 from "../components/home/Teaser2";
import Nanos from "../components/home/Nanos";
import NewsletterSubscribeSection from "../components/home/NewsletterSubscribeSection";
import JoinCommunity from "../components/home/JoinCommunity";
import Teaser3 from "../components/home/Teaser3";
import Roadmap from "../components/home/Roadmap";
import Partner from "../components/home/Partner";
import Team from "../components/home/Team";

const PageGridContainer = styled(Grid)`
    margin-left:  ${props => props.theme.spacing(0)};
    margin-right:  ${props => props.theme.spacing(0)};
    border: none;

    ${props => props.theme.breakpoints.up("xl")} {
        margin: ${props => props.theme.spacing(0, 'auto')};
    }

    // remove double border on top and end
    .MuiGrid2-root:first-of-type {
        border-top: none;
    }
        
        
    .MuiGrid2-root:last-of-type {
        border-bottom: none;
    }
`;

const GridItem = styled(Grid)`
    /* border-bottom: 1px solid ${props => props.theme.vars.palette.neutral['800']}; */
    overflow: hidden;
    width: 100%;
`;

const Home = () => {
    return (
        <>
            <Head>
                <title>META NANOs | Home</title>
            </Head>
            <PageGridContainer container>
                <GridItem xs={12}>
                    <Teaser />
                </GridItem>
                {/* <GridItem>
                    <Headlines />
                </GridItem> */}
                <GridItem xs={12}>
                    <WhatIsMetananos />
                </GridItem>
                <GridItem>
                    <Teaser2 />
                </GridItem>
                <GridItem xs={12}>
                    <Nanos />
                </GridItem>
                <GridItem xs={12} md={12} lg={6}>
                    <NewsletterSubscribeSection />
                </GridItem>
                <GridItem xs={12} md={12} lg={6}>
                    <JoinCommunity />
                </GridItem>
                <GridItem>
                    <Teaser3 />
                </GridItem>
                <GridItem xs={12}>
                    <Roadmap />
                </GridItem>
                <GridItem xs={12}>
                    <Team />
                </GridItem>
                <GridItem xs={12}>
                    <Partner />
                </GridItem>
            </PageGridContainer>
        </>
    )
}

export default Home;
