import { styled } from '@mui/material/styles';
import { Box } from "@mui/material";
import Image from 'next/image';

const ImageViewerStyled = styled(Box)`
    display: flex;
    position: relative;
    height: ${(props: any) => props.height || 'unset'};
    min-height: ${(props: any) => props.minHeight || '372px'};
    max-height: ${(props: any) => props.maxHeight || 'unset'};
    width: ${(props: any) => props.width || '100%'};
    overflow: hidden;
    z-index: 1;
    border-radius: ${props => props.borderRadius || props.theme.spacing(3)};
    background-color: ${(props: any) => props.background || 'none' };
    cursor: ${(props: any) => props.cursor || 'auto' };
`;

const UIImageViewer = (props) => {
    // we don't need the priority property inside the ImageViewerStyled, compiler complains
    let propsImageViewer = {...props};
    delete propsImageViewer?.priority;
    return (
        <ImageViewerStyled {...propsImageViewer}>
            {props.children}
            <Image priority={props.priority ? true: undefined} 
                title={props.alt} 
                alt={props.alt} 
                src={props.src} 
                fill sizes='100vw' 
                style={{ objectFit: props.objectfit || 'cover' }} 
            />
        </ImageViewerStyled>
    )
}

export default UIImageViewer;

// const UIImageViewer0 = (props) => {
//     return <BoxStyled {...props}>{props.children}
//         <Box className="image"></Box>
//     </BoxStyled>;
// };

// const BoxStyled = styled(Stack)`
//     border-radius: ${props => props.theme.spacing(3)};
//     overflow: hidden;
//     max-height: 372px;

//     .image {
//         background-image: url(${(props: any) => props.src});
//         width: 100%;
//         height: ${(props: any) => props.height || '372px'};
//         display: inline-flex;
//         background-position: center;
//         background-repeat: no-repeat;
//         background-size: cover;
//     }
// `;