import { SvgIcon } from "@mui/material";

const GooglePlayIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M13.286 6.73 10.752 9.33 2.475.857c.281-.06.613-.01.978.2l9.833 5.673ZM1.666 2.103c0-.247.041-.459.111-.641l8.332 8.526-8.335 8.544a1.774 1.774 0 0 1-.108-.637V2.103Zm9.086 8.544L2.468 19.14c.283.063.617.015.986-.197l9.85-5.686-2.551-2.611h-.001Zm3.357-3.443 3.058 1.765c.982.566.982 1.494 0 2.061l-3.039 1.754-2.732-2.797 2.713-2.783Z" /></svg>
        </SvgIcon>
    );
}

export default GooglePlayIcon;