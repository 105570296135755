import { useRef } from "react";
import { styled } from '@mui/material/styles';
import Slider from "react-slick";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Image from "next/image";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import GlobeIcon from "../base/icons/GlobeIcon";
import ArtstationIcon from "../base/icons/ArtstationIcon";
import UIButtonGroup from "../base/UIButtonGroup";

const teamMembers = [
    {
        "name": "Paul Polterauer",
        "title": "Founder",
        "imgSrc": "paul.webp",
        "description": "Serial Entrepreneur, CEO, Blockchain Eco Expert, Founder of HEROcoin and herosphere.gg",
        "social": [{
            "icon": "twitter",
            "url": "https://twitter.com/p4poulsson",
        }, {
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/paul-polterauer/"
        }]
    },
    {
        "name": "Philip Peinsold",
        "title": "Founder",
        "imgSrc": "philip.webp",
        "description": "Serial Entrepreneur, CTO, Software Dev, Blockchain Dev, Founder of HEROcoin and herosphere.gg",
        "social": [{
            "icon": "twitter",
            "url": "https://twitter.com/startuPhil",
        }, {
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/philippeinsold/"
        }]
    },
    {
        "name": "Manuel Thomasser",
        "title": "3D Lead",
        "imgSrc": "manuel.webp",
        "description": "Worked on League of Legends, Minions 2, Terminator, Halo, Carnival Row, Assassin's Creed, Call of Duty, ...",
        "social": [{
            "icon": "globe",
            "url": "https://www.cinlay.com",
        }, {
            "icon": "artstation",
            "url": "https://www.artstation.com/sgalpre",
        }
            , {
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/manuel-thomasser/"
        }]
    },
    {
        "name": "Jeffrey Frias",
        "title": "3D Modeller",
        "imgSrc": "jeff.webp",
        "description": "Worked on Mandalorian, Carnival Row, Roland Emmerich's Midway, ...",
        "social": [{
            "icon": "globe",
            "url": "https://www.artstation.com/j3ff",
        }, {
            "icon": "artstation",
            "url": "https://www.linkedin.com/in/jeffrey-delos-santos-frias-800822159/",
        }]
    },
    {
        "name": "Jens Kuczwara",
        "title": "Concept Artist",
        "imgSrc": "jens.webp",
        "description": "Worked on Diablo IV, Talking Tom, His Dark Materials 3, ...",
        "social": [{
            "icon": "artstation",
            "url": "https://www.artstation.com/braindrain",
        }, {
            "icon": "instagram",
            "url": "https://www.instagram.com/jens_kart",
        }]
    },
    {
        "name": "Andreas Petersson",
        "title": "Lead Blockchain Dev",
        "imgSrc": "andreas.webp",
        "description": "Founder of 'Capacity Blockchain Solutions', Founder of Bitcoin Austria, Co-Creator of Mycelium Wallet",
        "social": [{
            "icon": "twitter",
            "url": "https://twitter.com/yellowhatcoder",
        }, {
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/petersson-at/",
        }]
    },
    {
        "name": "Robin Rünstler",
        "title": "Game Developer",
        "imgSrc": "robin.webp",
        "description": "Unity Expert, Computer Scientist, AR/VR Enthusiast",
        "social": [{
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/robin-ruenstler",
        }]
    },
    {
        "name": "Petros Kitsaras",
        "title": "Game Developer",
        "imgSrc": "petros.webp",
        "description": "Full Stack Unity Software Engineer",
        "social": [{
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/petros-kitsaras/",
        }]
    },
    {
        "name": "German Rabenlehner",
        "title": "UI/UX Designer",
        "imgSrc": "german.webp",
        "description": "User Experience and Interface Expert, Graphic Designer, 3D Designer, Editorial Designer, PC Enthusiast, Visionary",
        "social": [{
            "icon": "linkedin",
            "url": "https://at.linkedin.com/in/german-rabenlehner-68b794bb",
        },
        {
            "icon": "behance",
            "url": "https://www.behance.net/rabenlehnergerman",
        }]
    },
    {
        "name": "Sam Güzel",
        "title": "Community Manager",
        "imgSrc": "sam.webp",
        "description": "Ecommerce, Social Media & Community Manager, Blockchain Addict",
        "social": [{
            "icon": "twitter",
            "url": "https://twitter.com/Crypto_Sam__",
        }, {
            "icon": "linkedin",
            "url": "https://www.instagram.com/xmirasol/",
        }]
    },
    {
        "name": "Diego Figura",
        "title": "Graphic design Community Manager",
        "imgSrc": "diego.webp",
        "description": "Community manager, graphic designer, content creator.",
        "social": [{
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/diegofigura/",
        },
        {
            "icon": "behance",
            "url": "https://www.behance.net/diegofigura",
        }]
    },
    {
        "name": "Agustin Gwozdz",
        "title": "Graphic design Community Manager",
        "imgSrc": "agustin.webp",
        "description": "Community manager, graphic designer, UX/UI designer, coffee enthusiast",
        "social": [{
            "icon": "linkedin",
            "url": "https://www.linkedin.com/in/agustin-gwozdz/",
        },
        {
            "icon": "behance",
            "url": "https://www.behance.net/agustingwozdz",
        }]
    },
];


const GriContainer = styled(Grid)`
    margin: ${props => props.theme.spacing(0, 'auto')};
    padding: ${props => props.theme.spacing(7.5, 2.5)};

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5, 10)};
    }

    .gridItemArrow {
        align-self: center;
    }
`;

const SliderItem = styled(Box)`
    padding: ${props => props.theme.spacing(2.5)};
`;

const CardStyled = styled(Grid)`
    max-width: 250px;
    min-height: 300px;
    margin: ${props => props.theme.spacing(0, 'auto')};
    padding: ${props => props.theme.spacing(3)};
    border-radius: ${props => props.theme.spacing(2.25)};
    background-color: ${props => props.theme.vars.palette.neutral['800']};
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    color: ${props => props.theme.vars.palette.neutral['300']};
`;

const AvatarStyled = styled(Image)`
    width: 80px;
    height: 80px;
    background: ${props => props.theme.vars.palette.gray['300']};
    backdrop-filter: blur(50px);
    border-radius: ${props => props.theme.spacing(2)};
`;

const SocialMediaButton = styled(Button)`
    width: 36px !important;
    height: 36px !important;
    min-width: unset;
    color: ${props => props.theme.vars.palette.text.primary};
    border-color: ${props => props.theme.vars.palette.text.primary};
` as typeof Button;

const SideArrowLeft = styled(Button)`
    width: 44px;
    height: 44px;
    background: none;
    cursor: pointer;
    border: 1px solid ${props => props.theme.vars.palette.brand['100']};
    border-radius: ${props => props.theme.spacing(1.5)};
    min-width: unset;
`;

const SideArrowRight = styled(Button)`
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background: none;
    cursor: pointer;
    border: 1px solid ${props => props.theme.vars.palette.brand['100']};
    border-radius: ${props => props.theme.spacing(1.5)};
    min-width: unset;
`;

const Team = () => {
    const slider = useRef<any>(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    let members: any = [];
    const chunkSize = 2;
    // divide the team member in rows of 2
    for (let i = 0; i < teamMembers.length; i += chunkSize) {
        const chunk = teamMembers.slice(i, i + chunkSize);
        members.push(chunk);
    }

    return (
        <GriContainer container gap={5}>
            <Grid xs={12}>
                <Typography variant='h3' color="neutral.100" textAlign={'center'}>MEET OUR TEAM</Typography>
            </Grid>
            <Grid xs={12} container>
                <Grid className="gridItemArrow" textAlign="left" xs={1}>
                    <SideArrowLeft aria-label="arrow-left" onClick={() => slider?.current?.slickPrev()}>
                        <KeyboardArrowLeftIcon />
                    </SideArrowLeft>
                </Grid>
                <Grid xs={10}>
                    <Slider ref={slider}{...settings}>
                        {
                            members.map((row, index) => {
                                return (
                                    <SliderItem key={index}>
                                        {
                                            row.map((member, index) => {
                                                return (
                                                    <SliderItem key={index}>
                                                        <CardStyled container gap={3}>
                                                            <Grid container xs={12} spacing={1}>
                                                                <Grid xs={5}>
                                                                    <AvatarStyled alt={member.name} width={80} height={80} src={`/images/team/${member.imgSrc}`} />
                                                                </Grid>
                                                                <Grid xs={7}>
                                                                    <Grid>
                                                                        <Typography variant="sm" fontWeight="fontWeightSemiBold">{member.name}</Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="xs">{member.title}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid xs={12}>
                                                                <Typography variant="xs">{member.description}</Typography>
                                                            </Grid>
                                                            <Grid xs={12}>
                                                                <UIButtonGroup>
                                                                    {member.social.map((socialItem, socialItemIndex) => {
                                                                        return (
                                                                            <SocialMediaButton key={socialItemIndex} href={socialItem.url}
                                                                                target={"_blank"} variant="outlined" size="small" tabIndex={-1}
                                                                                aria-label={socialItem.icon}>
                                                                                {socialItem.icon === 'twitter' &&
                                                                                    <Twitter />
                                                                                }
                                                                                {socialItem.icon === 'linkedin' &&
                                                                                    <LinkedIn />
                                                                                }
                                                                                {socialItem.icon === 'instagram' &&
                                                                                    <Instagram />
                                                                                }
                                                                                {(socialItem.icon === 'globe' || socialItem.icon === 'behance') &&
                                                                                    <GlobeIcon />
                                                                                }
                                                                                {socialItem.icon === 'artstation' &&
                                                                                    <ArtstationIcon />
                                                                                }
                                                                            </SocialMediaButton>
                                                                        )
                                                                    })}
                                                                </UIButtonGroup>
                                                            </Grid>
                                                        </CardStyled>
                                                    </SliderItem>
                                                );
                                            })
                                        }
                                    </SliderItem>
                                )
                            })
                        }
                    </Slider >
                </Grid>
                <Grid className="gridItemArrow" textAlign="right" xs={1}>
                    <SideArrowRight aria-label="arrow-right" onClick={() => slider?.current?.slickNext()}>
                        <KeyboardArrowRightIcon />
                    </SideArrowRight>
                </Grid>
            </Grid>
        </GriContainer>
    );
}

export default Team;