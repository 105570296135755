import { styled } from '@mui/material/styles';
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import Slider from "react-slick";
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import PolygonIcon from '../base/icons/PolygonIcon';
import { Instagram, Twitter } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DiscordIcon from '../base/icons/DiscordIcon';
import UIImageViewer from '../base/UIImageViewer';
import FreeMintTeaser from '../../public/images/home/free_mint_teaser.webp';
import PreMintTeaser from '../../public/images/home/pre_mint_teaser.webp';
import PublicMintTeaser from '../../public/images/home/public_mint_teaser.webp';
import GameTeaserImag1 from '../../public/images/home/game_teaser_1.webp';
import GameTeaserImag2 from '../../public/images/home/game_teaser_2.webp';
import GameTeaserImag3 from '../../public/images/home/game_teaser_3.webp';
import GameTeaserImag4 from '../../public/images/home/game_teaser_4.webp';
import GameTeaserImag5 from '../../public/images/home/game_teaser_5.webp';
import GameTeaserImag6 from '../../public/images/home/game_teaser_6.webp';
import AppleIcon from '@mui/icons-material/Apple';
import WindowsIcon from '../base/icons/WindowsIcon';
import GooglePlayIcon from '../base/icons/GooglePlayIcon';
import { getDownloadGameInfo } from '../../lib/device-detector';
import Light1 from '../../public/images/home/game_teaser_1_light.webp';
import Light2 from '../../public/images/home/game_teaser_2_light.webp';
import Image from 'next/image';

const SliderStyled = styled(Slider)`
    .slick-dots {
        display: flex;
        justify-content: center;
        bottom: ${props => props.theme.spacing(3)};

        li {
            button:before {
                color: ${props => props.theme.vars.palette.neutral['800']};
                font-size: 12px;
            }
            
            &.slick-active button:before {
                color: ${props => props.theme.vars.palette.neutral['50']};
            }
        }
    }
`

const Container = styled(Stack)`
    text-align: center;
    padding: ${props => props.theme.spacing(0, 3.33)};
    justify-content: center;
    align-items: center;

    ${props => props.theme.breakpoints.up("sm")} {
        padding: ${props => props.theme.spacing(0, 10)};
    }

    ${props => props.theme.breakpoints.up("xl")} {
        padding: ${props => props.theme.spacing(0, 20, 8)};
    }

    .brand {
        background: linear-gradient(284.43deg, #FF00C7 -120.75%, #8F00FF 125.12%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
        font-weight: ${props => props.theme.typography.fontWeightSemiBold};
    }
`;

const ContentContainer = styled(Stack)`
    max-width: 950px;
    margin: ${props => props.theme.spacing(0, 'auto')};
    text-align: center;
    align-items: center;
    gap: ${props => props.theme.spacing(3)};
`;

const ContentBox = styled(Box)`
    position: relative;
    margin: ${props => props.theme.spacing(5, 'auto', 4)} !important;
`;

const PolygonTypography = styled(Typography)`
    color: ${props => props.theme.vars.palette.neutral['100']};

    svg {
        font-size: 18px;
    }
`

const SocialMediaButton = styled(IconButton)`
    width: 44px;
    height: 44px;
    color: white;
    background-color: ${props => props.theme.vars.palette.brand['100']};
    border: none;

    &.Mui-disabled {
        border: 1px solid ${props => props.theme.vars.palette.neutral['600']};
    }
` as typeof Button;

const LightImage = styled(Image)`
    position: absolute;
    top: -31px;
    z-index: 8;
    -webkit-filter: blur(100px);
    filter: blur(100px);
    width: 536px;
    height: 330px;
    left: 50%;
    margin-left: -268px;
`;

const Teaser = () => {
    const router = useRouter();
    const slider = useRef<any>(null);
    const freeMintEnabled = process.env.NEXT_PUBLIC_FEATURE_FREE_MINT_ENABLED === 'true' ? true : false;
    const preMintEnabled = process.env.NEXT_PUBLIC_FEATURE_PRE_MINT_ENABLED === 'true' ? true : false;
    const publicMintEnabled = process.env.NEXT_PUBLIC_FEATURE_PUBLIC_MINT_ENABLED === 'true' ? true : false;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [],
        autoplay: true,
        autoplaySpeed: 10000,
    };
    const [downloadGameInfo, setDownloadGameInfo] = useState({ url: '', device: '' });

    useEffect(() => {
        setDownloadGameInfo(getDownloadGameInfo());
    }, []);

    const navigateTo = (path) => {
        router.push(`/${path}`);
    }

    return (
        <SliderStyled ref={slider}{...settings}>
            {freeMintEnabled && !preMintEnabled && !publicMintEnabled &&
                <Container spacing={2}>
                    <UIImageViewer src={FreeMintTeaser} alt="META NANOs mint is live now!" minHeight={432} borderRadius="0" />
                    <ContentBox>
                        <ContentContainer>
                            <PolygonTypography variant='xs'><PolygonIcon /> Polygon</PolygonTypography>
                            <Typography variant='h1' component="span" color="neutral.100">ALPHA PASS FREE MINT
                                <Typography variant='inherit' className="brand">IS NOW LIVE</Typography>
                            </Typography>
                            <Typography variant='body1'>
                                Are you ready to join the NANOverse? Get your first NANOs now before it's too late and start competing in the NANO Games.
                            </Typography>
                            <Button sx={{ width: '224px' }} onClick={() => { navigateTo('wallet') }} variant="contained" tabIndex={-1}>Enter mint</Button>
                        </ContentContainer>
                        <LightImage src={Light1} alt="Lighting Free mint" width={536} height={330} />
                    </ContentBox>
                </Container>
            }
            {freeMintEnabled && preMintEnabled && !publicMintEnabled &&
                <Container spacing={2}>
                    <UIImageViewer src={PreMintTeaser} alt="META NANOs mint is live now!" minHeight={432} borderRadius="0" />
                    <ContentBox>
                        <ContentContainer>
                            <PolygonTypography variant='xs'><PolygonIcon /> Polygon</PolygonTypography>
                            <Typography variant='h1' component="span" color="neutral.100">ALPHA PASS PRE-MINT
                                <Typography variant='inherit' className="brand">IS NOW LIVE</Typography>
                            </Typography>
                            <Typography variant='body1'>
                                Are you ready to join the NANOverse? Get your first NANOs now before it's too late and start competing in the NANO Games.
                            </Typography>
                            <Button sx={{ width: '224px' }} onClick={() => { navigateTo('wallet') }} variant="contained" tabIndex={-1}>Enter mint</Button>
                        </ContentContainer>
                        <LightImage src={Light1} alt="Lighting Premint" width={536} height={330} />
                    </ContentBox>
                </Container>
            }
            {freeMintEnabled && preMintEnabled && publicMintEnabled &&
                <Container spacing={2}>
                    <UIImageViewer src={PublicMintTeaser} alt="META NANOs mint is live now!" minHeight={432} borderRadius="0"/>
                    <ContentBox>
                        <ContentContainer>
                            <PolygonTypography variant='xs'><PolygonIcon /> Polygon</PolygonTypography>
                            <Typography variant='h1' component="span" color="neutral.100">PUBLIC MINT
                                <Typography variant='inherit' className="brand">IS NOW LIVE</Typography>
                            </Typography>
                            <Typography variant='body1'>
                                Are you ready to join the NANOverse? Get your first NANOs now before it's too late and start competing in the NANO Games.
                            </Typography>
                            <Button sx={{ width: '224px' }} onClick={() => { navigateTo('mint-nano?type=public') }} variant="contained" tabIndex={-1}>Enter mint</Button>
                        </ContentContainer>
                    </ContentBox>
                </Container>
            }
            
            <Container spacing={2}>
                <UIImageViewer priority={true} src={GameTeaserImag2} alt="META NANOs Feel the power" minHeight={432} borderRadius="0" />
                <ContentBox>
                    <ContentContainer>
                        <PolygonTypography variant='xs'><PolygonIcon /> Polygon</PolygonTypography>
                        <Typography variant='h1' component="span" color="neutral.100">META NANOs
                            <Typography variant='inherit' className="brand">Feel the power</Typography>
                        </Typography>
                        <Typography variant='body1'>
                            META NANOs is a universe of high quality 3D NFT games where users own, boost, breed and trade their NFT game avatars “the NANOs”. Players globally compete against each other and show the powers of their NANOs.
                        </Typography>
                        <Grid container justifyContent="center" spacing={1} gap={1}>
                            <Grid>
                                <SocialMediaButton href={"https://discord.gg/dnbRVBNfCb"} aria-label="Discord button" target={"_blank"} tabIndex={-1} variant="contained">
                                    <DiscordIcon />
                                </SocialMediaButton>
                            </Grid>
                            <Grid>
                                <SocialMediaButton href={"https://twitter.com/metananos"} aria-label="Twitter button" target={"_blank"} tabIndex={-1} variant="contained">
                                    <Twitter />
                                </SocialMediaButton>
                            </Grid>
                            <Grid>
                                <SocialMediaButton href={"https://instagram.com/metananos"} aria-label="Instagram button" target={"_blank"} tabIndex={-1} variant="contained">
                                    <Instagram />
                                </SocialMediaButton>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                    <LightImage src={Light1} alt="Lighting Social" width={536} height={330} />
                </ContentBox>
            </Container>
            <Container spacing={2}>
                <UIImageViewer src={GameTeaserImag1} alt="META NANOs Rumble Race" minHeight={432} borderRadius="0" />
                <ContentBox>
                    <ContentContainer>
                        <PolygonTypography variant='sm'><PolygonIcon /> Polygon</PolygonTypography>
                        <Typography variant='h1' component="span" color="neutral.100">META NANOs Rumble Race
                            <Typography variant='inherit' className="brand">3, 2, 1... run!</Typography>
                        </Typography>
                        <Typography variant='body1'>
                            Enjoy intense action in a multiplayer racing game. Use the power gem abilities of your NANO to smash down your opponents and cross the finish line before anyone else. Play the “Training Mode” to learn the tactics and collect XP points. If you ready, play the “Battle Mode” and win crypto.
                        </Typography>
                        <Grid container justifyContent="center" spacing={1} gap={1}>
                            <Grid>
                                <SocialMediaButton href={downloadGameInfo.url} target={"_blank"} tabIndex={-1} variant="contained">
                                    {downloadGameInfo.device === 'mac' &&
                                        <AppleIcon />
                                    }
                                    {downloadGameInfo.device === 'windows' &&
                                        <WindowsIcon />
                                    }
                                </SocialMediaButton>
                            </Grid>
                            <Grid>
                                <SocialMediaButton disabled href={"https://instagram.com/metananos"} target={"_blank"} tabIndex={-1} variant="contained">
                                    <GooglePlayIcon />
                                </SocialMediaButton>
                            </Grid>
                        </Grid>
                        <Typography variant='sm'>Open Beta - Download the game here!</Typography>
                    </ContentContainer>
                    <LightImage src={Light1} alt="Lighting Game" width={536} height={330} />
                </ContentBox>
            </Container>
            <Container spacing={2}>
                <UIImageViewer src={GameTeaserImag4} alt="Go plant a tree!" minHeight={432} borderRadius="0" />
                <ContentBox>
                    <ContentContainer>
                        <PolygonTypography variant='xs'><PolygonIcon /> Polygon</PolygonTypography>
                        <Typography variant='h1' component="span" color="neutral.100">Hey You! Yeah You!
                            <Typography variant='inherit' className="brand">GO plant a tree!</Typography>
                        </Typography>
                        <Typography variant='body1'>
                            We teamed up with “Tree Nation” to stop deforestation. Follow us on Twitter and retweet our post about this awesome collaboration. For every retweet, we plant a tree! Let’s fight climate change and make this world a better place.
                        </Typography>
                        <Button sx={{ width: '224px' }} href={"https://twitter.com/metananos"} target={"_blank"} variant="contained" tabIndex={-1}>
                            <Twitter /> Go to Twitter
                        </Button>
                    </ContentContainer>
                    <LightImage src={Light1} alt="Lighting Social 2" width={536} height={330} />
                </ContentBox>
            </Container>
            <Container spacing={2}>
                <UIImageViewer src={GameTeaserImag6} alt="First Cyrpto Game with AI integrated" minHeight={432} borderRadius="0" />
                <ContentBox>
                    <ContentContainer>
                        <PolygonTypography variant='xs'><PolygonIcon /> Polygon</PolygonTypography>
                        <Typography variant='h1' component="span" color="neutral.100">THE AI HAS BEEN RELEASED
                            <Typography variant='inherit' className="brand">MEET THE NANOs</Typography>
                        </Typography>
                        <Typography variant='body1'>
                            Enjoy intense action in a multiplayer racing game. Use the power gem abilities of your NANO to smash down your opponents and cross the finish line before anyone else. Play the "Training Mode" to learn the tactics and collect XP points. If you are ready, play the "Battle Mode" and win crypto.
                        </Typography>
                        <Button sx={{ width: '224px' }} onClick={() => { navigateTo('showroom') }} variant="contained" tabIndex={-1}>Talk to Nerlin</Button>
                    </ContentContainer>
                    <LightImage src={Light2} alt="Lighting AI" width={536} height={330} />
                </ContentBox>
            </Container>
        </SliderStyled>
    );
}

export default Teaser;