import { SvgIcon } from "@mui/material";

const PolygonIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18"><g clipPath="url(#a)"><path d="M15.582 5.48c-.376-.215-.86-.215-1.29 0l-3.008 1.773L9.242 8.38l-2.955 1.773c-.377.215-.86.215-1.29 0l-2.31-1.397a1.31 1.31 0 0 1-.645-1.128V4.942c0-.43.215-.86.645-1.128l2.31-1.343c.376-.215.86-.215 1.29 0l2.31 1.397c.376.215.645.644.645 1.128V6.77l2.042-1.182V3.76c0-.43-.215-.86-.645-1.128L6.34.107c-.376-.215-.86-.215-1.29 0L.646 2.686C.215 2.9 0 3.33 0 3.76v5.051c0 .43.215.86.645 1.128l4.352 2.526c.376.215.86.215 1.29 0l2.955-1.72 2.042-1.182 2.955-1.72c.376-.214.86-.214 1.29 0l2.31 1.344c.376.215.645.645.645 1.129v2.686c0 .43-.215.86-.645 1.128l-2.257 1.344c-.376.215-.86.215-1.29 0l-2.31-1.344a1.31 1.31 0 0 1-.645-1.128v-1.72l-2.041 1.183v1.773c0 .43.214.86.644 1.128l4.353 2.526c.376.215.86.215 1.29 0l4.351-2.526a1.31 1.31 0 0 0 .645-1.128V9.133c0-.43-.215-.86-.645-1.128L15.582 5.48Z" fill="#8247E5"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20.633v18H0z"/></clipPath></defs></svg>
        </SvgIcon>
    );
}

export default PolygonIcon;

