import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Slider from "react-slick";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ArrowCircleDownIcon from "../base/icons/ArrowCircleDownIcon";

const milestones = [
    {
        when: 'achieved',
        topics: [
            'Drop of Alpha Pass NFTs',
            'Implemented Online Wallet for NFTs',
            'Free NANO Mint for Alpha Pass Holders',
            'Released Beta Version of First Game: Rumble Race',
            'Released Weekly Leaderboards and Rewards',
        ]
    },
    {
        when: 'now',
        topics: [
            'Mobile Version of Game',
            'NANO Pre-Mint and Public Sale',
            'Improve Gameplay Experience',
            'More Interaction with your NANO',
        ]
    },
    {
        when: 'next',
        topics: [
            'HEROcoin Integration',
            'Player Profile',
            'Private Lobbies',
        ]
    },
    {
        when: 'later',
        topics: [
            'Concept Creation of 2nd Game',
            'Custodial Wallet',
            'Breeding',
            'Training',
        ]
    },
    {
        when: 'future',
        topics: [
            'More games',
            'DAO Token',
            'Lending',
            'Crafting',
            'Open Platform for 3rd Party Game Developers',
        ]
    },
];

const GridContainer = styled(Grid)`
    position: relative;
    margin: ${props => props.theme.spacing(0, 'auto')};
    padding: ${props => props.theme.spacing(7.5, 2.5)};
    text-align: center;

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5, 10)};
    }
`;

const Milestone = styled(Grid)`
    padding: ${props => props.theme.spacing(3)};    
    
    .content {
        min-height: 400px;
        max-width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: ${props => props.theme.spacing(1.5)};
        margin: ${props => props.theme.spacing(0, 'auto')};
        background: ${props => props.theme.palette.neutral['800']};
        padding: ${props => props.theme.spacing(3)};
        text-align: left;

        ${props => props.theme.breakpoints.up("xl")} {
            min-height: 350px;
        }

        .when {
            width: 30px;
            height: 30px;
            margin-bottom: ${props => props.theme.spacing(3)};
            &.achieved {
                filter: drop-shadow(0px 0px 30px ${props => props.theme.palette.green['400']});
                color: ${props => props.theme.palette.green['400']};
            }

            &.now {
                filter: drop-shadow(0px 0px 30px ${props => props.theme.palette.amber['600']});
                color: ${props => props.theme.palette.amber['600']};
            }

            &.next {
                filter: drop-shadow(0px 0px 30px #FF8F51);
                color: #FF8F51;
            }

            &.later {
                filter: drop-shadow(0px 0px 30px ${props => props.theme.palette.brand['100']});
                color: ${props => props.theme.palette.brand['100']};
            }

            &.future {
                filter: drop-shadow(0px 0px 30px ${props => props.theme.palette.neutral['200']});
                color: ${props => props.theme.palette.neutral['200']};
            }
        }
    }
`;

const Roadmap = () => {
    const slider = useRef<any>(null);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1240,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <GridContainer container>
            <Grid xs={12}>
                <Typography variant='h3' color="neutral.100" textAlign={'center'} marginBottom={10}>ROADMAP</Typography>
            </Grid>
            <Grid xs={12} justifyContent="center">
                <Slider ref={slider} {...settings}>
                    {
                        milestones.map((milestone, index) => {
                            return (
                                <Milestone key={index} >
                                    <Box className="content">
                                        {milestone.when === 'achieved' && <CheckCircleIcon className={`when ${milestone.when}`} />}
                                        {milestone.when === 'now' && <ArrowCircleDownIcon className={`when ${milestone.when}`} />}
                                        {milestone.when === 'next' && <ArrowCircleRightIcon className={`when ${milestone.when}`} />}
                                        {milestone.when === 'later' && <ArrowCircleRightIcon className={`when ${milestone.when}`} />}
                                        {milestone.when === 'future' && <WatchLaterIcon className={`when ${milestone.when}`} />}
                                        <Typography variant="body1" textAlign="center" color="neutral.300" fontWeight="fontWeightSemiBold" sx={{ textTransform: 'uppercase' }}>{milestone.when}</Typography>
                                        <ul>
                                            {
                                                milestone.topics.map((topic, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <Typography variant="sm">{topic}</Typography>
                                                        </li>

                                                    )
                                                })
                                            }
                                        </ul>
                                    </Box>
                                </Milestone>
                            );
                        })
                    }
                </Slider>
            </Grid>
        </GridContainer>
    );
}

export default Roadmap;