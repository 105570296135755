import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import UIImageViewer from '../base/UIImageViewer';
import Image from 'next/image';
import HeroCoin from '../../public/images/partner/herocoin.webp';
import SmapeCapital from '../../public/images/partner/smape.webp';
import Capacity from '../../public/images/partner/capacity.webp';
import Svlaw from '../../public/images/partner/svlaw.webp';
import Cbb from '../../public/images/partner/cbb.webp';
import DeQuest from '../../public/images/partner/dequest.webp';
import Polygon from '../../public/images/partner/polygon.webp';
import Outlier from '../../public/images/partner/outlier.webp';
import Light from '../../public/images/home/partner_light.webp';

const GridContainer = styled(Grid)`
    position: relative;
    padding: ${props => props.theme.spacing(7.5, 5)};
    text-align: center;
    align-content: center; 
    justify-content: center;

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5, 10)};
    }
`;

const GridItemPartner = styled(Grid)`
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 174px;
        background-color: #2B2B2B;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        padding: ${props => props.theme.spacing(0, 2.5)};
        border-radius: ${props => props.theme.spacing(2.25)};
        height: 85px;
    }
`;

const PartnerImage = styled(UIImageViewer)`
    max-height: 45px;
    max-width: 100%;
    align-self: center;
`;

const LinkStyled = styled(Link)`
    margin: ${props => props.theme.spacing(0, 'auto')};
    cursor: pointer;
    overflow: hidden;
    a, img, div {
        cursor: pointer;
    }
`;

const LightImage = styled(Image)`
    position: absolute;
    width: 450px;
    height: 272px;
    left: 543px;
    margin-right: -225px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    bottom: 0px;
    z-index: -1;
`;

const Partner = () => {
    return (
        <GridContainer container spacing={3} gap={5}>
            <Grid xs={12}>
                <Typography variant='h3' color="neutral.100" textAlign={'center'}>OUR PARTNERS</Typography>
            </Grid>
            <Grid container xs={12} md={12} gap={3} justifyContent="center" columns={16}>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://www.herocoin.io"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={HeroCoin} alt="Herocoin partner" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://www.smape.capital"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={SmapeCapital} alt="Smape Capital partner" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://capacity.at"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={Capacity} alt="Capacity blockchain solutions partner" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://www.svlaw.at"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={Svlaw} alt="Stadler Völkel Rechtsanwälte partner" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://cryptobullandbear.io/"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={Cbb} alt="Crytpo Bull and Bear" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://beta.dequest.io/quests"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={DeQuest} alt="DeQuest.io" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://www.polygon.technology/"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={Polygon} alt="Polygon" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
                <GridItemPartner xs={7} md={3}>
                    <LinkStyled href={"https://outlierventures.io/"} target={"_blank"} rel="noreferrer">
                        <PartnerImage src={Outlier} alt="Outlier Ventures" height={45} objectfit="contain"/>
                    </LinkStyled>
                </GridItemPartner>
            </Grid>
            <LightImage src={Light} alt="Partner lighting" width={250} height={272}/>
        </GridContainer>
    );
}

export default Partner;