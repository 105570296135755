import { styled } from '@mui/material/styles';
import { Button, Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import DiscordIcon from '../base/icons/DiscordIcon';
import { Twitter } from '@mui/icons-material';
import UIButtonGroup from '../base/UIButtonGroup';

const Container = styled(Grid)`
    color: ${props => props.theme.vars.palette.neutral['100']};
    padding: ${props => props.theme.spacing(7.5, 10)};

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5, 10)};
    }

    ${props => props.theme.breakpoints.up("xl")} {
        padding: ${props => props.theme.spacing(7.5, 20)};
    }
`;

const GridItem = styled(Grid)`
    text-align: center;

    ${props => props.theme.breakpoints.up("xl")} {
        text-align: left;
    }
`

const UIButtonGroupStyled = styled(UIButtonGroup)`
    flex-direction: column;
    gap: ${props => props.theme.spacing(3)};
    align-items: center;
    margin: ${props => props.theme.spacing(0, 'auto')};
    
    ${props => props.theme.breakpoints.up("sm")} {
        flex-direction: row;
        justify-content: center;
        max-width: 500px;
    }

    ${props => props.theme.breakpoints.up("lg")} {
        max-width: unset;
    }

    a, button {
        width: 100%;
        margin: ${props => props.theme.spacing(0, 'auto')};
    }
`;

const JoinCommunity = () => {
    return (
        <Container container spacing={3}>
            <GridItem xs={12}>
                <Typography variant='h4' component="span">JOIN OUR AWESOME COMMUNITY</Typography>
            </GridItem>
            <GridItem xs={12}>
                <Typography variant='body1'>
                    Be part of one of the most exciting communities around all play-to-earn games and metaverses.
                    Keep yourself updated on news, secrets and upcoming events!
                </Typography>
            </GridItem>
            <GridItem xs={12}>
                <UIButtonGroupStyled> 
                    <Button color="discord" href={"https://discord.gg/dnbRVBNfCb"} target={"_blank"} variant="contained">
                        <DiscordIcon /> Discord Channel
                    </Button>
                    <Button color="twitter" href={"https://twitter.com/metananos"} target={"_blank"} variant="contained">
                        <Twitter /> Twitter Channel
                    </Button>
                </UIButtonGroupStyled>
            </GridItem>
        </Container>
    );
}

export default JoinCommunity;