import { styled } from '@mui/material/styles';
import { Stack, Typography } from "@mui/material";

const Container = styled(Stack)`
    max-width: 830px;
    margin: ${props => props.theme.spacing(0, 'auto')};
    padding: ${props => props.theme.spacing(7.5, 2.5)};

    ${props => props.theme.breakpoints.up("xl")} {
        padding: ${props => props.theme.spacing(7.5, 10)};
    }
`;

const WhatIsMetananos = () => {
    return (
        <Container spacing={3}>
            <Typography variant='h3' color="neutral.100" textAlign={'center'}>WHAT IS META NANOs?</Typography>
            <Typography variant='body1' textAlign="center">
                Imagine a universe of different games where you decide which games to play with the avatars you own. META NANOs is exactly that: A platform for collaboratively developed NFT games on the Polygon blockchain. Our avatars called “NANOs” with their secret powers, abilities and awesome looks will be your fighters in the NANO Games. 
                You will “play to earn”, “play to own” but most of all - play to have fun.
            </Typography>
        </Container>
    );
}

export default WhatIsMetananos;