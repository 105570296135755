import { useRef } from "react";
import { styled } from '@mui/material/styles';
import Image from "next/image";
import Slider from "react-slick";
import { Box, Button, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
// nanos
import aurelia from '../../public/images/nanos/home/aurelia.webp';
import bob from '../../public/images/nanos/home/bob.webp';
import chappy from '../../public/images/nanos/home/chappy.webp';
import ethenia from '../../public/images/nanos/home/ethenia.webp';
import greenEugene from '../../public/images/nanos/home/green_eugene.webp';
import horcHogan from '../../public/images/nanos/home/horc_hogan.webp';
import hurtCobain from '../../public/images/nanos/home/hurt_cobain.webp';
import igorTodd from '../../public/images/nanos/home/igor_todd.webp';
import illyria from '../../public/images/nanos/home/illyria.webp';
import indianaBones from '../../public/images/nanos/home/indiana_bones.webp';
import jackBearow from '../../public/images/nanos/home/jack_bearow.webp';
import jayTheDonRockson from '../../public/images/nanos/home/jay_the_don_rockson.webp';
import metanator from '../../public/images/nanos/home/metanator.webp';
import morganTreeman from '../../public/images/nanos/home/morgan_treeman.webp';
import nerlin from '../../public/images/nanos/home/nerlin.webp';
import samUrai from '../../public/images/nanos/home/sam_urai.webp';
import sergeantHops from '../../public/images/nanos/home/sergeant_hops.webp';
import shayla from '../../public/images/nanos/home/shayla.webp';
import sirRender1st from '../../public/images/nanos/home/sir_render_1st.webp';
import skylar from '../../public/images/nanos/home/skylar.webp';
import sockyBalboa from '../../public/images/nanos/home/socky_balboa.webp';
import sparky from '../../public/images/nanos/home/sparky.webp';
import starWalker from '../../public/images/nanos/home/star_walker.webp';
import thePandalorian from '../../public/images/nanos/home/the_pandalorian.webp';
import thorix from '../../public/images/nanos/home/thorix.webp';

const nanos = [
    {
        image: aurelia,
        name: "Aurelia",
    },
    {
        image: bob,
        name: "Bob",
    },
    {
        image: chappy,
        name: "Chappy",
    },
    {
        image: ethenia,
        name: "Ethenia",
    },
    {
        image: greenEugene,
        name: "Green Eugene",
    },
    {
        image: horcHogan,
        name: "Horc Hogan",
    },
    {
        image: hurtCobain,
        name: "Hurt Cobain",
    },
    {
        image: igorTodd,
        name: "Igor Todd",
    },
    {
        image: illyria,
        name: "Illyria",
    },
    {
        image: indianaBones,
        name: "Indiana Bones",
    },
    {
        image: jackBearow,
        name: "Jack Bearow",
    },
    {
        image: jayTheDonRockson,
        name: "Jay The Don Rockson",
    },
    {
        image: metanator,
        name: "Metanator",
    },
    {
        image: morganTreeman,
        name: "Morgan Treeman",
    },
    {
        image: nerlin,
        name: "Nerlin",
    },
    {
        image: samUrai,
        name: "Sam Urai",
    },
    {
        image: sergeantHops,
        name: "Sergeant Hops",
    },
    {
        image: sirRender1st,
        name: "Sir Render 1st",
    },
    {
        image: skylar,
        name: "Skylar",
    },
    {
        image: sockyBalboa,
        name: "Socky Balboa",
    },
    {
        image: sparky,
        name: "Sparky",
    },
    {
        image: starWalker,
        name: "Star Walker",
    },
    {
        image: thePandalorian,
        name: "The Pandalorian",
    },
    {
        image: thorix,
        name: "Thorix",
    },
    {
        image: shayla,
        name: "Shayla",
    },
];

const GridContainer = styled(Grid)`
    ${props => props.theme.breakpoints.down("md")} {
        flex-direction: column-reverse;
    }
`;

const GridItem = styled(Grid)`
    margin: ${props => props.theme.spacing(0, 'auto')};
    padding: ${props => props.theme.spacing(7.5, 2.5)};

    ${props => props.theme.breakpoints.up("md")} {
        padding: ${props => props.theme.spacing(7.5)};
    }

    &.border {
        padding-top: ${props => props.theme.spacing(0)};
        
        ${props => props.theme.breakpoints.up("lg")} {
            padding: ${props => props.theme.spacing(10)};
            /* border-right: 1px solid ${props => props.theme.vars.palette.neutral['800']}; */
        }

        ${props => props.theme.breakpoints.up("xl")} {
            padding: ${props => props.theme.spacing(10, 10, 10, 20)};
        }
    }

    .gridItemTitle {
        text-align: center;

        ${props => props.theme.breakpoints.up("md")} {
            text-align: left;
        }
    }

    .gridItemDescription {
        text-align: center;

        ${props => props.theme.breakpoints.up("md")} {
            text-align: left;
        }
    }

    .gridItemCta {
        text-align: center;
    }
`

const NanoContainer = styled(Box)`
    width: 168px;
    height: 288px;
`;

const Nanos2 = () => {
    const slider = useRef<any>(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1550,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1070,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 490,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 346,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    return (
        <GridContainer container justifyContent="center">
            {/* <GridItem xs={12} md={4} className="border" container gap={3}>
                <Grid xs={12} className="gridItemTitle">
                    <Typography variant='h3' color="neutral.100">OWN - PLAY - WIN</Typography>
                </Grid>
                <Grid xs={12} className="gridItemDescription">
                    <Typography variant="body1">
                        Select your favourite NANO from 125 different characters. Join the NANO Games and turn your NFT into a true champion.
                    </Typography>
                </Grid>
                <Grid xs={12} className="gridItemCta">
                    <Button sx={{ width: '100%', maxWidth: '365px' }} variant="contained" href="/showroom">Meet the Nanos</Button>
                </Grid>
            </GridItem> */}
            <GridItem xs={12}>
                <Slider ref={slider} {...settings}>
                    {
                        nanos.map((nano, index) => {
                            return (
                                <NanoContainer key={index}>
                                    <Image alt={nano.name} src={nano.image} width={168} height={288} />
                                </NanoContainer>
                            );
                        })
                    }
                </Slider>
            </GridItem>
        </GridContainer>
    );
}

export default Nanos2;